import React from 'react'
import { Heading, Row, Text } from '@te-digi/styleguide'

const TextAffixExample = () => (
  <>
    <Row>
      <Text>Tiedot haettu väestötietojärjestelmästä</Text>
      <Text variant={'suffix'}>VTJ</Text>
    </Row>
    <Row>
      <Heading>
        Well there's nothing like the dark halitosis singing on the shore.
        <Text
          size={'md'}
          variant={'suffix'}
        >
          suffix
        </Text>
      </Heading>
    </Row>
  </>
)

export { TextAffixExample }
