import React from 'react'

import { Inline, Paragraph, Text } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { TextAffixExample } from '../../examples/react/TextAffixExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Text"
    components={[{ component: Text }]}
  >
    <Section title="Värin vaihtaminen">
      <Playground>
        <Inline
          gap="md"
          wrap="wrap"
        >
          <Text color="primary">Primary</Text>
          <Text color="secondary">Secondary</Text>
          <Text color="danger">Danger</Text>
          <Text color="success">Success</Text>
          <Text color="warning">Warning</Text>
          <Text color="info">Info</Text>
          <Text color="dark">Dark</Text>
        </Inline>
      </Playground>
      <Playground format="html">
        <div className="flex flex-wrap mt-n-md">
          <span className="text-primary mr-md mt-md">Primary</span>
          <span className="text-secondary mr-md mt-md">Secondary</span>
          <span className="text-danger mr-md mt-md">Danger</span>
          <span className="text-success mr-md mt-md">Success</span>
          <span className="text-warning mr-md mt-md">Warning</span>
          <span className="text-info mr-md mt-md">Info</span>
          <span className="text-dark mr-md mt-md">Dark</span>
        </div>
      </Playground>
    </Section>
    <Section title="Tekstin muotoilu">
      <Playground>
        <>
          <Text
            as="p"
            fontWeight="semi-bold"
          >
            Lihavointi
          </Text>
          <Text
            as="p"
            fontStyle="italic"
          >
            Kursiivi
          </Text>
          <Text
            as="p"
            transform="uppercase"
          >
            Isot kirjaimet
          </Text>
        </>
      </Playground>
      <Playground format="html">
        <>
          <p className="text-weight-semi-bold mb-0">Lihavointi</p>
          <p className="text-style-italic mb-0">Kursiivi</p>
          <p className="text-transform-uppercase mb-0">Isot kirjaimet</p>
        </>
      </Playground>
    </Section>
    <Section title="Fonttikoon vaihtaminen">
      <Playground>
        <Text
          as="p"
          size="sm"
        >
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
        </Text>
      </Playground>
      <Playground format="html">
        <p className="text-sm mb-0">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
        </p>
      </Playground>
    </Section>
    <Section title="Rivivälin vaihtaminen">
      <Playground>
        <Text
          as="p"
          lineHeight="xs"
        >
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis
          facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus
          auctor vitae, consectetuer et venenatis eget velit. Sed augue orci,
          lacinia eu tincidunt et eleifend nec lacus. Donec ultricies nisl ut
          felis, suspendisse potenti.
        </Text>
      </Playground>
      <Playground format="html">
        <p className="text-line-height-xs mb-0">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis
          facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus
          auctor vitae, consectetuer et venenatis eget velit. Sed augue orci,
          lacinia eu tincidunt et eleifend nec lacus. Donec ultricies nisl ut
          felis, suspendisse potenti.
        </p>
      </Playground>
    </Section>
    <Section title="Tekstin tasaus">
      <Playground>
        <Text align="left">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis
          facilisis sed sapien.
        </Text>
      </Playground>
      <Playground>
        <Text align="center">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis
          facilisis sed sapien.
        </Text>
      </Playground>
      <Playground>
        <Text align="right">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis
          facilisis sed sapien.
        </Text>
      </Playground>
    </Section>
    <Section title="Tekstin katkaisu">
      <Playground>
        <Text
          as="p"
          truncate
        >
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque iure
          culpa perferendis ullam quia dignissimos dolorem magni magnam cum,
          placeat aliquid nostrum adipisci nobis ea itaque facilis velit quis
          doloremque vitae recusandae? Doloribus dolorum voluptatum illum
          possimus dolore, cumque impedit vel quisquam aut ab qui velit
          repellendus id consequuntur numquam!
        </Text>
      </Playground>
    </Section>
    <Section title="Rivimäärän rajoittaminen">
      <Playground>
        <Text lines={2}>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque iure
          culpa perferendis ullam quia dignissimos dolorem magni magnam cum,
          placeat aliquid nostrum adipisci nobis ea itaque facilis velit quis
          doloremque vitae recusandae? Doloribus dolorum voluptatum illum
          possimus dolore, cumque impedit vel quisquam aut ab qui velit
          repellendus id consequuntur numquam!
        </Text>
      </Playground>
    </Section>
    <Section title="Suffix-variantti">
      <Paragraph>
        Teksti on mahdollista muokata suffix-variantilla toisen tekstin sivuun
        lisätietokentäksi. Variantin pääasiallinen käyttötarkoitus on
        lähdetietojen merkitseminen.
      </Paragraph>
      <Playground example={TextAffixExample} />
    </Section>
  </Content>
)

export default Page
